<template>
  <div class="question-container">
    <img
      :src="question.image_url"
      alt="Question Image"
      class="question-image"
    />
    <p class="question-text">{{ question.question["it-IT"] }}</p>
    <div class="answers-container">
      <button
        class="answer-button"
        v-for="answer in question.answers"
        :key="answer.id"
        @click="selectAnswer(answer)"
      >
        {{ answer.answer["it-IT"] }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: Object,
  },
  methods: {
    selectAnswer(answer) {
      this.$emit("answer-selected", answer);
    },
  },
};
</script>

<style scoped>
.question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.question-image {
  max-width: 100%;
  border-radius: 3px;
  margin-bottom: 20px;
}

.question-text {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.answers-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.answer-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.answer-button:hover {
  background-color: #45a049;
}
</style>
