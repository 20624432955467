<template>
  <div class="timer-container">
    <p class="timer-text">Time left: {{ timeLeft }} seconds</p>
  </div>
</template>

<script>
export default {
  props: {
    maxTime: Number,
  },
  data() {
    return {
      timeLeft: this.maxTime,
    };
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          clearInterval(this.timer);
          this.$emit("time-up");
        }
      }, 1000);
    },
    resetTimer() {
      clearInterval(this.timer);
      this.timeLeft = this.maxTime;
      this.startTimer();
    },
  },
};
</script>

<style scoped>
.timer-container {
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}

.timer-text {
  font-size: 1rem;
  color: #f44336;
  font-weight: bold;
}
</style>
