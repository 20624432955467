<script>
import { IonGrid, IonRow, IonCol } from "@ionic/vue";

export default {
  name: "ModalQuizResult",
  props: {
    score: Number,
    maxScore: Number,
    user: Object,
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
  },
  data() {
    return {
      success: false,
      closePath: "",
      jsConfetti: null,
    };
  },
  created() {
    // Determine if the quiz was passed based on the score
    this.success = this.score >= this.maxScore;
    // Generate the close path based on route parameters
    this.closePath = this.generateClosePath();

    if (this.success) {
      this.showConfetti();
    }
  },

  methods: {
    showConfetti() {
      console.log("TRIG CONFETTI");

      this.$confetti.start();

      setTimeout(() => {
        this.$confetti.stop();
      }, 1500);
    },
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    generateClosePath() {
      // Generate the close path using route parameters
      return (
        "/campaign/learning/" +
        this.$route.params.parentId +
        "/" +
        encodeURIComponent(this.$route.params.parentTitle) +
        "/" +
        this.$route.params.parent
      );
    },
    closeModal() {
      // Emit close event to parent component
      this.$emit("close");
    },
  },
};
</script>

<template>
  <ion-grid class="results">
    <!-- User Avatar with Animation -->
    <ion-row class="ion-justify-content-center">
      <ion-col size="7">
        <img
          :src="user.data.avatar_url"
          @error="handleImgError"
          alt="User Avatar"
          class="avatar"
          ref="avatar"
        />
      </ion-col>
    </ion-row>

    <!-- User Name -->
    <ion-row class="ion-justify-content-center">
      <ion-col size="auto">
        <h2 class="user-name">{{ user.data.nome }}</h2>
      </ion-col>
    </ion-row>

    <ion-row v-if="!success" class="ion-justify-content-center">
      <ion-col size="auto">
        <span class="emoji"> 🙁 🙁 🙁 </span>
      </ion-col>
    </ion-row>

    <!-- Success or Failure Message with Animation -->
    <ion-row class="ion-justify-content-center">
      <ion-col
        :class="{ 'ion-text-success': success, 'ion-text-danger': !success }"
      >
        <h4 class="result-message" ref="message">
          {{
            success
              ? "Complimenti! Hai superato il test!"
              : "Non hai raggiunto il numero di risposte giuste per accedere al blocco successivo, riprova"
          }}
        </h4>
      </ion-col>
    </ion-row>

    <!-- Close Button -->
    <ion-row class="ion-justify-content-center">
      <ion-col size="auto">
        <a class="button-secondary" :href="closePath" @click="closeModal"
          >Chiudi</a
        >
        <ion-button @click="showConfetti">confetti</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<style scoped>
canvas {
  background-color: crimson !important;
}
.results {
  width: 100%;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-light);
  padding: 2rem 1rem;
  text-align: center;
}

.avatar {
  border-radius: 50%;
  max-width: 100%;
  height: auto;
  animation: fadeInUp 0.5s ease;
}

.user-name {
  margin-top: 1rem;
  font-size: 1.5rem;
}

.result-message {
  margin-top: 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  animation: scaleIn 0.5s ease;
}

.ion-text-success {
  color: var(--ion-color-success);
}

.ion-text-danger {
  color: var(--ion-color-danger);
}

.button-secondary {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-light);
  text-decoration: none;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
  margin-top: 2rem;
}

.button-secondary:hover {
  background-color: var(--ion-color-secondary-shade);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

.emoji {
  font-size: 3rem;
}
</style>
